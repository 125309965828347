import React from "react"
import { Product, Layout, SEO } from "../components"

export default function PageTemplate({ pageContext }) {
  const products = pageContext.products

  const links = [
    {
      name: "DE LÍNEA",
      url: "/arreglos/de-linea",
    },
    {
      name: "ECOLÓGICOS",
      url: "/arreglos/ecologicos",
    },
    {
      name: "EDICIÓN LIMITADA",
      url: "/arreglos/edicion-limitada",
    },
    {
      name: "ESPECIALES",
      url: "/arreglos/especiales",
    },
  ]

  return (
    <Layout currentLink="Arreglos" showSubMenu={true} links={links}>
      <SEO title="Arreglos Florales" />
      <section className="products">
        {products.length === 0 && <p className="no-products">Por el momento no tenemos productos en esta categoría.</p>}
        {products.length > 0 &&
          products.map(product => (
            <Product
              title={product.title}
              image={product.images[0]}
              url={`/arreglos/${pageContext.handle}/${product.handle}`}
              price={product.variants[0].price}
              key={`product-${product.id}`}
            />
          ))}
      </section>
    </Layout>
  )
}
